import { graphql, Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const SingleBeerStyles = styled.div`
  border: 1px solid var(--grey);
  padding: 2rem;
  text-align: center;
  > a {
    cursor: none;
    text-decoration: none;
    pointer-events: none;
  }
  img {
    width: 300%;
    height: 600px;
    object-fit: contain;
    display: grid;
    -moz-box-align: center;
    align-items: center;
    font-size: 30px;
  }
`;

export const SingleBeer = ({ beer }) => (
  <SingleBeerStyles>
    <img src={beer.image} alt={beer.name} className="center" />
    <Link to={`/beer/${beer.id}`}>
      <h3>{beer.name}</h3>
      {beer.price}
    </Link>
    <p title={`${Math.round(beer.rating.average)} out of 5 stars`}>
      <span>{`${`⭐`.repeat(Math.round(beer.rating.average))}`}</span>
      <span style={{ filter: 'grayscale(100%)' }}>{`${`⭐`.repeat(
        Math.round(5 - beer.rating.average)
      )}`}</span>
      <span>({beer.rating.reviews})</span>
    </p>
  </SingleBeerStyles>
);

const SingleBeerPage = ({ data: { beer } }) => (
  <>
    <SingleBeer beer={beer} />
    <Link to="/beers" className="center">
      <h2 className="center">Back to Beers</h2>
    </Link>
  </>
);
export default SingleBeerPage;

export const query = graphql`
  query GetBeer($beerId: String!) {
    beer(id: { eq: $beerId }) {
      name
      price
      id
      image
      rating {
        average
        reviews
      }
    }
  }
`;
